@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply bg-white
    }
}

html
{
    scroll-snap-type: y mandatory;
}

ul
{
    margin-top: 1em;
    list-style-type: symbols(cyclic '✚');
    list-style-position: outside;
    margin-left: 20px;
    margin-bottom: 1.4em;
}

li
{
    margin-bottom: 0.5em;
    padding-left: 10px;
}

li::marker
{
    color: #E9B100;
}

p
{
    margin-bottom: 1.5em;
}

p:last-child
{
    margin-bottom: 0;
}

.markdown img 
{
    width: 100%;
    height: auto;
}

/* Hide RECAPTCHA bagde > must include branding in form manually (see https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed) */
.grecaptcha-badge
{
    visibility: hidden;
}